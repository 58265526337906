import { InputNumber } from "primereact/inputnumber";
import PropTypes from "prop-types";
import React from "react";
const InputDivNumber = (fieldName) => {
  return (
    <div className="p-inputnumber">
      <InputNumber
        disabled={fieldName.disabled}
        inputStyle={{
          ...fieldName.customStyle,
          color: "black",
          fontWeight: "bold",
          fontSize: 12,
          borderColor: "#A9A9A9",
          borderWidth: 1,
          marginLeft: 10,
        }}
        style={{ ...fieldName.customStyle }}
        suffix={fieldName.suffix}
        showButtons={fieldName.showButtons || false}
        id={fieldName.name}
        value={fieldName?.value}
        onValueChange={fieldName.handleChange}
      />
    </div>
  );
};

InputDivNumber.defaultProps = {};
export default InputDivNumber;
