import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable"; // The default

const BaseForm = ({
  actions,
  general,
  caller = "",
  data = {},
  displayComponentFromProp = false,
  componentToDisplayFromProps,
}) => {
  // console.log('state du form :', modalGlobaldivsDisplay);
  // console.log('a render :', modalGlobaldivsDisplay.formToDisplay);

  // Component to display can come from Redux for Main form or from Props in Route for subforms
  let ComponentToDisplay;

  ComponentToDisplay = componentToDisplayFromProps;

  // console.log("component from baseform", ComponentToDisplay);

  return (
    <Draggable handle="top">
      <div className="DraggableDiv" key="draggable">
        <div className={`genericFormBoilerplate `} style={{ zIndex: 5 }}>
          <div>
            <div className="BorderTop" />
          </div>
          <div
            className="closingButton"
            onClick={() => {
              actions.setHideSubForm();
              try {
                data.updateParent();
              } catch (e) {
                console.error(e);
              }
            }}
          >
            <a id="aa" href="#" className="close">
              .
            </a>
          </div>
          <div className={`gen_insideForm`}>
            <ComponentToDisplay
              caller={caller}
              {...general?.propsToPass}
              data={data}
              general={general}
              actions={actions}
            />
          </div>
        </div>
      </div>
    </Draggable>
  );
};

BaseForm.propTypes = {
  actions: PropTypes.object.isRequired,
  // modalGlobaldivsDisplay: PropTypes.object.isRequired,
  caller: PropTypes.string,
  mode: PropTypes.string,
  possibleLevelOfSubform: PropTypes.number,
  displayComponentFromProp: PropTypes.bool,
  componentToDisplayFromProps: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  data: PropTypes.object,
  isSubFormLarge: PropTypes.bool,
  forceSmall: PropTypes.bool,
};

BaseForm.defaultProps = {
  caller: "",
  mode: "",
  possibleLevelOfSubform: 0,
  displayComponentFromProp: false,
  componentToDisplayFromProps: {},
  data: {},
  isSubFormLarge: false,
  forceSmall: false,
};

export default BaseForm;
