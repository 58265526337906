import { React, useState } from "react";
import axios from "../../services/axios/Axios";
import "./b2bpreparation.css";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import {
  HealthData,
  healthGrids,
  NeededServicesData,
  ProfileStatusEnum,
} from "../../services/enums";
import { InputText } from "primereact/inputtext";
import HighlightSwitch from "../Base/HighLightSwitch";
import { Slider } from "primereact/slider";
import {
  PRIMARY_GREEN,
  PRIMARY_RED,
  PRIMARY_YELLOW,
  SECONDARY_RED,
} from "../../styles/colors";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabPanel, TabView } from "primereact/tabview";
import { Tooltip } from "primereact/tooltip";

const B2BPreparation = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [zipCodes, setZipCodes] = useState([]);
  const [newZipCode, setNewZipCode] = useState("");
  const [needs, setNeeds] = useState([]);
  const [searchRange, setSearchRange] = useState(0);
  const [autonomyLoss, setAutonomyLoss] = useState([]);
  const [mobility, setMobility] = useState([]);
  const [mentalHealth, setMentalHealth] = useState([]);
  const [roles, setRoles] = useState([]);
  const defaultRoles = ["HELPEE", "HELPER", "HELPRO"];
  const [nbProfilesPerZipCode, setNbProfilesPerZipCode] = useState([]);
  console.log("nbProfilesPerZipCode", nbProfilesPerZipCode);

  const handleAddZipCodes = () => {
    const splitZipCodes = newZipCode.split(",").map((zip) => zip.trim());
    setZipCodes([...zipCodes, ...splitZipCodes]);
    setNewZipCode(""); // Clear input after adding
  };

  const handleRemoveZipCode = (zipCodeToRemove) => {
    setZipCodes(zipCodes.filter((zipCode) => zipCode !== zipCodeToRemove));
  };

  const searchProfiles = () => {
    if (
      zipCodes.length === 0 ||
      searchRange === 0 ||
      roles.length === 0 ||
      needs.length === 0 ||
      autonomyLoss.length === 0 ||
      mobility.length === 0 ||
      mentalHealth.length === 0
    ) {
      alert("Veuillez remplir tous les champs");
      return;
    }
    axios
      .get("/search/getProfilesCountPerZipCodes", {
        params: {
          zipCodes: zipCodes,
          searchRange: searchRange,
          roles: roles,
          needs: needs,
          autonomyLoss: autonomyLoss,
          mobility: mobility,
          mentalHealth: mentalHealth,
        },
      })
      .then((response) => {
        setNbProfilesPerZipCode(response.data);
      })
      .catch((error) => {
        console.log("Error B2B prep search profiles", error);
      });
  };

  const exportToCSV = () => {
    setIsLoading(true);

    // Prepare headers by extracting keys from the first object and excluding 'zipCodeProfiles'
    const headers = Object.keys(nbProfilesPerZipCode[0])
      .filter((key) => key !== "zipCodeProfiles")
      .join(",");

    // Prepare values by iterating over each object in the array
    const values = nbProfilesPerZipCode
      .map((profile) => {
        // Filter out the 'zipCodeProfiles' key for each profile
        const { zipCodeProfiles, ...filteredData } = profile;

        // Convert the remaining data to a CSV-friendly string
        return Object.values(filteredData)
          .map((value) => {
            // Ensure churnRate or any other numeric value is formatted correctly
            if (typeof value === "number") {
              return `"${value}"`;
            }
            return `"${value}"`;
          })
          .join(",");
      })
      .join("\n"); // Join rows by newlines

    // Create CSV content
    const csvContent = `${headers}\n${values}`;

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Generate a URL for the Blob and create a link element
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "profiles-per-zipcodes.csv");

    // Append link to the document and trigger download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Reset loading state
    setIsLoading(false);
  };

  const formatProfileStatusField = (rowData) => {
    try {
      const correctStatus = ProfileStatusEnum.find(
        (status) => status.name === rowData
      );
      const status = {
        color: correctStatus.color,
        name: correctStatus.name,
      };
      return <span style={{ color: status.color }}>{status.name}</span>;
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "20px 40px",
        gap: 20,
      }}
    >
      <h1>Préparation des clients</h1>
      <div style={{ width: 220 }}>
        <Button
          label="Rechercher les profils"
          onClick={searchProfiles}
          loading={isLoading}
          style={{ backgroundColor: PRIMARY_RED, outline: 0, border: 0 }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          gap: "20px",
          alignItems: "flex-start",
          flexWrap: "wrap",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
          <div
            style={{
              border: "2px solid black",
              borderColor: PRIMARY_GREEN,
              padding: 10,
              borderRadius: 10,
            }}
          >
            <div>
              <h3 style={{ marginBottom: 10 }}>Codes postaux</h3>
              {zipCodes.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                    marginBottom: 5,
                  }}
                >
                  {zipCodes.map((zipCode, index) => (
                    <Badge
                      key={index}
                      value={zipCode}
                      style={{
                        cursor: "pointer",
                        backgroundColor: PRIMARY_GREEN,
                      }}
                      onClick={() => handleRemoveZipCode(zipCode)} // Allow user to remove zip code on click
                    />
                  ))}
                  <Tooltip target=".zipcode-delete-info" />
                  <i
                    className="zipcode-delete-info pi pi-info-circle"
                    data-pr-tooltip="Vous pouvez supprimer un code postal en cliquant sur le badge"
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center+10"
                    style={{
                      fontSize: "1rem",
                      cursor: "pointer",
                      marginLeft: 10,
                    }}
                  />
                </div>
              ) : (
                <p style={{ marginBottom: 5 }}>Aucun code postal ajouté</p>
              )}
            </div>

            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <InputText
                placeholder="Code postaux (séparés par des virgules)"
                value={newZipCode}
                style={{ height: 20 }}
                onChange={(e) => setNewZipCode(e.target.value)}
              />
              <Button
                label="Ajouter"
                onClick={handleAddZipCodes}
                size="small"
                style={{
                  backgroundColor: PRIMARY_GREEN,
                  outline: 0,
                  border: 0,
                  width: 100,
                  height: 20,
                  fontSize: "small",
                }}
              />
              <i
                className="zipcode-info pi pi-info-circle"
                data-pr-tooltip="Vous pouvez ajouter plusieurs codes postaux à la suite (séparés par des virgules)"
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center+10"
                style={{ fontSize: "1rem", cursor: "pointer" }}
              />
            </div>
            <Tooltip target=".zipcode-info" />
          </div>
          <div
            style={{
              border: "2px solid black",
              borderColor: PRIMARY_YELLOW,
              padding: 10,
              borderRadius: 10,
            }}
          >
            <h3 style={{ marginBottom: 10 }}>Rayon de recherche</h3>
            <div
              style={{
                display: "flex",
                gap: 5,
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <InputText
                value={searchRange}
                style={{ height: 20 }}
                onChange={(e) => setSearchRange(e.target.value)}
              />
              <h2>km</h2>
            </div>
            <Slider
              value={searchRange}
              onChange={(e) => setSearchRange(e.value)}
              style={{ marginBottom: 10 }}
            />
          </div>
          <div
            style={{
              border: "2px solid black",
              borderColor: SECONDARY_RED,
              padding: 10,
              borderRadius: 10,
            }}
          >
            <h3 style={{ marginBottom: 10 }}>Rôles</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 5,
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              {defaultRoles.map((role) => {
                let highlightColor;
                switch (role) {
                  case "HELPEE":
                    highlightColor = PRIMARY_RED;
                    break;
                  case "HELPER":
                    highlightColor = PRIMARY_YELLOW;
                    break;
                  case "HELPRO":
                    highlightColor = PRIMARY_GREEN;
                    break;
                  default:
                    break;
                }

                return (
                  <HighlightSwitch
                    key={role + "switch"}
                    isHighlighted={roles.indexOf(role) !== -1}
                    highlightColorProp={highlightColor}
                    onClick={() => {
                      if (roles.indexOf(role) === -1) {
                        setRoles([...roles, role]);
                      } else {
                        roles.splice(roles.indexOf(role), 1);
                        setRoles([...roles]);
                      }
                    }}
                    text={role}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="preparation-highlight-container">
          <div
            className="preparation-highlight-section"
            style={{ borderColor: PRIMARY_RED }}
          >
            <h3>Besoins</h3>
            {healthGrids?.neededServices?.map((info, index) => {
              return (
                <div style={{ display: "inline-block" }} key={index}>
                  <HighlightSwitch
                    key={index + "switch"}
                    isHighlighted={needs?.indexOf(info) !== -1}
                    highlightColorProp={PRIMARY_RED}
                    onClick={() => {
                      if (needs.indexOf(info) === -1) {
                        setNeeds([...needs, info]);
                      } else {
                        needs.splice(needs.indexOf(info), 1);
                        setNeeds([...needs]);
                      }
                    }}
                    text={NeededServicesData[info].text}
                  />
                </div>
              );
            })}
          </div>
          <div
            className="preparation-highlight-section"
            style={{ borderColor: PRIMARY_GREEN }}
          >
            <h3>Autonomie</h3>
            {healthGrids?.autonomyLoss?.map((info, index) => {
              return (
                <div style={{ display: "inline-block" }} key={index}>
                  <HighlightSwitch
                    key={index + "switch"}
                    isHighlighted={autonomyLoss?.indexOf(info) !== -1}
                    highlightColorProp={PRIMARY_GREEN}
                    onClick={() => {
                      if (autonomyLoss.indexOf(info) === -1) {
                        setAutonomyLoss([...autonomyLoss, info]);
                      } else {
                        autonomyLoss.splice(autonomyLoss.indexOf(info), 1);
                        setAutonomyLoss([...autonomyLoss]);
                      }
                    }}
                    text={HealthData[info].text}
                  />
                </div>
              );
            })}
          </div>
          <div
            className="preparation-highlight-section"
            style={{ borderColor: PRIMARY_YELLOW }}
          >
            <h3>Mobilité</h3>
            {healthGrids?.mobilities?.map((info, index) => {
              return (
                <div style={{ display: "inline-block" }} key={index}>
                  <HighlightSwitch
                    key={index + "switch"}
                    isHighlighted={mobility?.indexOf(info) !== -1}
                    highlightColorProp={PRIMARY_YELLOW}
                    onClick={() => {
                      if (mobility.indexOf(info) === -1) {
                        setMobility([...mobility, info]);
                      } else {
                        mobility.splice(mobility.indexOf(info), 1);
                        setMobility([...mobility]);
                      }
                    }}
                    text={HealthData[info].text}
                  />
                </div>
              );
            })}
          </div>
          <div
            className="preparation-highlight-section"
            style={{ borderColor: SECONDARY_RED }}
          >
            <h3>Santé mentale</h3>
            {healthGrids?.mentalHealth?.map((info, index) => {
              return (
                <div style={{ display: "inline-block" }} key={index}>
                  <HighlightSwitch
                    key={index + "switch"}
                    isHighlighted={mentalHealth?.indexOf(info) !== -1}
                    highlightColorProp={SECONDARY_RED}
                    onClick={() => {
                      if (mentalHealth.indexOf(info) === -1) {
                        setMentalHealth([...mentalHealth, info]);
                      } else {
                        mentalHealth.splice(mentalHealth.indexOf(info), 1);
                        setMentalHealth([...mentalHealth]);
                      }
                    }}
                    text={HealthData[info].text}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div style={{ width: 165, alignSelf: "flex-end" }}>
        <Button
          style={{
            color: "white",
            backgroundColor: PRIMARY_YELLOW,
            border: "none",
          }}
          loading={isLoading}
          disabled={nbProfilesPerZipCode.length === 0}
          size="small"
          label="Exporter les profils"
          onClick={() => {
            exportToCSV();
          }}
        />
      </div>
      <div>
        <DataTable
          value={nbProfilesPerZipCode}
          style={{ width: "100%" }}
          className="p-datatable-striped"
        >
          <Column field="zipCode" header="Code postal" />
          <Column
            field="nbAuxiliaryProfiles"
            header="Aide à la toilette et repas"
          />
          <Column field="nbSportProfiles" header="Sport, bien être beauté" />
          <Column field="nbOtherProfiles" header="Compagnie, etc" />
          <Column field="count" header="Total" />
        </DataTable>
      </div>
      <div>
        <TabView>
          {nbProfilesPerZipCode.length > 0 &&
            nbProfilesPerZipCode.map((zipCode) => (
              <TabPanel
                key={zipCode.zipCode}
                header={`Code Postal: ${zipCode.zipCode}`}
              >
                <DataTable
                  value={zipCode.zipCodeProfiles}
                  style={{ width: "100%" }}
                  className="p-datatable-striped"
                  selectionMode={"single"}
                  onSelectionChange={(e) => {
                    window.open(`/profils?detailedProfileId=${e.value.userId}`);
                  }}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[5, 10, 20]}
                >
                  <Column field="id" header="ID" />
                  <Column field="firstName" header="Prénom" />
                  <Column field="lastName" header="Nom" />
                  <Column field="role" header="Rôle" />
                  <Column field="zipCode" header="Code postal" />
                  <Column
                    field="profileStatus"
                    header="Statut"
                    body={(rowData) => {
                      return formatProfileStatusField(rowData.profileStatus);
                    }}
                  />
                </DataTable>
              </TabPanel>
            ))}
        </TabView>
      </div>
    </div>
  );
};

export default B2BPreparation;
