import { hot } from "react-hot-loader/root";
import PropTypes from "prop-types";
import React from "react";
import "primeicons/primeicons.css";
import { Route, Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Page from "./Page";
import Home from "../Home/container";
import logo from "../../images/LOGO DEAR HELPEE RVB_fond_transparent.png";
import { Button } from "primereact/button";
import MyMenu from "../MyMenu";
import jwtDecode from "jwt-decode";
import { setConfig } from "react-hot-loader";
import Dashboard from "../Dashboard/container";
import B2BPreparation from "../B2BPreparation/container";
import Profils from "../Profils/container";
import DetailedProfil from "../Profils/DetailedProfil";
import BlackDiv from "../BlackDiv";
import BaseForm from "../BaseForm/container";
import Reservation from "../Reservation/container";
import InternalTesting from "../InternalTesting/container";
import Company from "../Company/container";
import Missions from "../Missions/container";
import KpiDashboard from "../KpiDashboard";

setConfig({
  showReactDomPatchNotification: false,
});

class RouterRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settedRef: null,
      isMenuOpen: false, // State to control the menu visibility
    };
    this.wrapperMenuRef = React.createRef(); // Reference to the menu to detect outside clicks
    this.handleClickOutside = this.handleClickOutside.bind(this); // Bind the method for outside click
  }

  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside); // Add event listener for outside click
    let decoded;
    if (
      localStorage.getItem(
        "adal.access.token.key495b14ec-0d72-4e07-be86-914f8b84f7fb"
      ) !== null
    ) {
      decoded = jwtDecode(
        localStorage.getItem(
          "adal.access.token.key495b14ec-0d72-4e07-be86-914f8b84f7fb"
        )
      );
    } else {
      decoded = jwtDecode(sessionStorage.getItem("adal.idtoken"));
    }

    this.props.actions.setDecoded(decoded);
  }

  componentWillUnmount() {
    // Remove the event listener when the component unmounts to prevent memory leaks
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    this.setState({ isMenuOpen: false }); // Close the menu
  }

  setRef = (value) => {
    this.wrapperMenuRef = value;
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen, // Toggle the menu state
    }));
  };

  navigate = (url) => {
    if (url.startsWith("/")) {
      this.props.history.push(url);
    }
  };

  render() {
    let decoded = "";
    try {
      if (
        localStorage.getItem(
          "adal.access.token.key495b14ec-0d72-4e07-be86-914f8b84f7fb"
        ) !== null
      ) {
        decoded = jwtDecode(
          localStorage.getItem(
            "adal.access.token.key495b14ec-0d72-4e07-be86-914f8b84f7fb"
          )
        );
      } else {
        decoded = jwtDecode(sessionStorage.getItem("adal.idtoken"));
      }
    } catch (e) {
      console.error("Error while decoding JWT", e);
    }

    const page = this.props.location.pathname.split("/")[1];

    return (
      <>
        <div className={`skel-${page || "home"}`} websocket={this.state.ws}>
          <div className={"skel-header"}>
            <div id="header-showMenu">
              <Button
                icon="pi pi-bars"
                id="header-btnToggleMenu"
                className="p-button-secondary"
                onClick={this.toggleMenu} // Toggle menu on button click
                label=""
              />
            </div>
            <Link id="header-logo" to="/">
              <img id="header-imgLogo" alt="logo DH" src={logo} />
            </Link>
            <span style={{ marginLeft: "20px", fontSize: "10px" }}>
              Version 1.2.0
            </span>
            <div id="header-userIcon" className="header-userIcon">
              {decoded?.name &&
                decoded?.name
                  .split(" ")
                  .map((n) => n[0])
                  .join("")
                  .toUpperCase()}
            </div>
          </div>
          <div className="skel-content">
            {this.props.general.isDisplayedOneOrSeveralSubForms && (
              <BlackDiv actions={this.props.actions} />
            )}

            {this.props.general.listOfSubformsDisplayed.map(
              (subform, index) => (
                <BaseForm
                  possibleLevelOfSubform={index}
                  displayComponentFromProp
                  componentToDisplayFromProps={subform.component}
                  data={subform.data}
                  isSubFormLarge={subform.isSubFormLarge}
                  key={index}
                  forceSmall={subform.forceSmall}
                />
              )
            )}

            <MyMenu
              ref={this.state.settedRef}
              popup={true}
              setRef={(e) => this.setRef(e)}
              className={
                this.state.isMenuOpen ? "skel-menu" : "skel-menu-hidden" // Toggle based on state
              }
              id="Menu"
            />
            <Route
              path="/"
              exact
              render={(props) => (
                <Page {...props} component={Home} title="Dear Helpee" />
              )}
            />
            <Route
              path="/profils"
              render={(props) => (
                <Page
                  {...props}
                  component={Profils}
                  title="Liste des Profils"
                />
              )}
            />
            <Route
              path="/dashboard"
              render={(props) => (
                <Page {...props} component={Dashboard} title="Dashboard SAV" />
              )}
            />
            <Route
              path="/preparation-b2b"
              render={(props) => (
                <Page
                  {...props}
                  component={B2BPreparation}
                  title="Préparation B2B"
                />
              )}
            />
            <Route
              path="/detailedProfil"
              render={(props) => (
                <Page
                  {...props}
                  component={DetailedProfil}
                  title="Détail du profil"
                />
              )}
            />

            <Route
              path="/reservation"
              render={(props) => (
                <Page {...props} component={Reservation} title="Reservation" />
              )}
            />
            <Route
              path="/internal-testing"
              render={(props) => (
                <Page
                  {...props}
                  component={InternalTesting}
                  title="Reservation"
                />
              )}
            />
            <Route
              path="/company"
              render={(props) => (
                <Page {...props} component={Company} title="Entreprise" />
              )}
            />
            <Route
              path="/missions"
              render={(props) => (
                <Page {...props} component={Missions} title="Missions" />
              )}
            />
            <Route
              path="/kpi-dashboard"
              render={(props) => (
                <Page {...props} component={KpiDashboard} title="KPI" />
              )}
            />
          </div>
        </div>
      </>
    );
  }
}

RouterRoute.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  classes: PropTypes.object,
};

RouterRoute.defaultProps = {
  classes: {},
};

export default withRouter(hot(RouterRoute));
