import React, { useEffect, useState } from "react";
import axios from "../../services/axios/Axios";

import { Button } from "primereact/button";
import { isEmpty } from "lodash";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const Reservation = (props) => {
  const [reservation, setReservation] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedCell, setSelectedCell] = useState(false);

  const ActivityType = {
    TALKING: "talking",
    READING: "reading",
    COOKING: "cooking",
    GARDENING: "gardening",
    CARDS: "cards",
    COGNITIVE_GAMES: "cognitiveGames",
    GAMES: "games",
    MUSIC: "music",
    DRAWING: "drawing",
    KNITTING: "knitting",
    LABOR: "labor",
    TINKERING: "tinkering",
    SHOPPING: "shopping",
    CINEMA: "cinema",
    RESTAURANT: "restaurant",
    WALKING: "walking",
    MUSEUM: "museum",
    FRIENDS: "friends",
    OUTSIDE_MEETING: "outsideMeeting",
    SELFCARE: "selfCare",
    RELAXATION: "relaxation",
    PHYSICAL_ACTIVITY: "physicalActivity",
    SOFT_PHYSICAL_ACTIVITY: "softPhysicalActivity",
    OUTSIDE_SPORT: "outsideSport",
    SPORT: "sport",
    HOUSE_CARE: "houseCare",
    ORGANIZING: "organizing",
    DAILY_CHORE: "dailyChore",
    INTERNET: "internet",
    ANIMALS: "animals",
    OTHER: "other",
    HAIR_CARE: "hairCare",
    BODY_CARE: "bodyCare",
    HAND_FEET_CARE: "handFeetCare",
    MAKEUP: "makeUp",
    MUSIC_ART: "musicArt",
    DRAW_ART: "drawArt",
    ADMINISTRATIVE_AID: "administrativeAid",
  };
  const ActivityData = {
    [ActivityType.TALKING]: {
      text: "Papoter",
    },
    [ActivityType.READING]: {
      text: "Lire",
    },
    [ActivityType.COOKING]: {
      text: "Cuisiner",
    },
    [ActivityType.GARDENING]: {
      text: "Jardiner",
    },
    [ActivityType.CARDS]: {
      text: "Jeux de cartes",
    },
    [ActivityType.GAMES]: {
      text: "Jeux de société",
    },
    [ActivityType.COGNITIVE_GAMES]: {
      text: "Jeux cognitifs",
    },
    [ActivityType.MUSIC]: {
      text: "Jouer d’un instrument",
    },
    [ActivityType.DRAWING]: {
      text: "Dessiner / Peindre",
    },
    [ActivityType.KNITTING]: {
      text: "Coudre / Tricoter",
    },
    [ActivityType.LABOR]: {
      text: "Travaux manuels",
    },
    [ActivityType.TINKERING]: {
      text: "Bricoler",
    },
    [ActivityType.SHOPPING]: {
      text: "Shopping",
    },
    [ActivityType.CINEMA]: {
      text: "Ciné - Théatre",
    },
    [ActivityType.RESTAURANT]: {
      text: "Restaurant",
    },
    [ActivityType.WALKING]: {
      text: "Se promener",
    },
    [ActivityType.MUSEUM]: {
      text: "Musée / culture",
    },
    [ActivityType.FRIENDS]: {
      text: "Voir des amis",
    },
    [ActivityType.OUTSIDE_MEETING]: {
      text: "RDV extérieurs",
    },
    [ActivityType.SELFCARE]: {
      text: "Prendre soin de soi",
    },
    [ActivityType.RELAXATION]: {
      text: "Activité de relaxation",
    },
    [ActivityType.PHYSICAL_ACTIVITY]: {
      text: "Activité Physique",
    },
    [ActivityType.SOFT_PHYSICAL_ACTIVITY]: {
      text: "Activités Motrices Douces",
    },
    [ActivityType.OUTSIDE_SPORT]: {
      text: "Accompagnement sorties sportives",
    },
    [ActivityType.SPORT]: {
      text: "Activités sportives",
    },
    [ActivityType.HOUSE_CARE]: {
      text: "Ménage",
    },
    [ActivityType.ORGANIZING]: {
      text: "Petits rangements du quotidien",
    },
    [ActivityType.DAILY_CHORE]: {
      text: "Petits travaux du quotidien",
    },
    [ActivityType.INTERNET]: {
      text: "Surfer sur internet",
    },
    [ActivityType.ANIMALS]: {
      text: "S’occuper d’animaux",
    },
    [ActivityType.OTHER]: {
      text: "Autre",
    },
    [ActivityType.HAIR_CARE]: {
      text: "Coiffure",
    },
    [ActivityType.BODY_CARE]: {
      text: "Soins corps/visage",
    },
    [ActivityType.HAND_FEET_CARE]: {
      text: "Soins mains/pieds",
    },
    [ActivityType.MAKEUP]: {
      text: "Maquillage",
    },
    [ActivityType.MUSIC_ART]: {
      text: "Musique/Chant",
    },
    [ActivityType.DRAW_ART]: {
      text: "Dessin/Peinture",
    },
    [ActivityType.ADMINISTRATIVE_AID]: {
      text: "Aide administrative",
    },
  };

  const initContent = async () => {
    await axios.get(`/reservations/getAllReservations`).then((resp) => {
      console.log(resp.data.reservations);
      setReservation(resp.data.reservations);
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(data, fileName + EXCEL_EXTENSION);
      }
    });
  };
  const exportExcel = () => {
    let arrangedTab = [];
    arrangedTab.push(Object.keys(reservation[0]));
    for (let i = 0; i < reservation.length; i++) {
      arrangedTab.push(Object.values(reservation[i]));
    }
    import("xlsx").then((xlsx) => {
      const moment = require("moment");

      const worksheet = xlsx.utils.json_to_sheet(arrangedTab);
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"],
      };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, moment(Date.now()).format("YY-MM-DD"));
    });
  };
  const frais = (rowData) => {
    if (rowData?.paymentMethod === "ONLINE") {
      return 1;
    } else if (rowData?.paymentMethod === "CASH") {
      return 0;
    } else {
      return 0.8;
    }
  };

  const commission = (rowData) => {
    if (rowData?.paymentMethod === "ONLINE_LEGACY") {
      return (rowData.rate.price * 15) / 100;
    } else {
      return 0;
    }
  };

  const cancelReservation = (id, fromId, toId) => {
    axios
      .put(`/reservations/${id}/${fromId}/${toId}/cancelFromBO`)
      .then((resp) => {
        console.log(resp.data);
        initContent();
      });
  };
  const cancelTemplate = (rowData) => {
    return (
      <Button
        onClick={() =>
          cancelReservation(
            rowData.id,
            rowData.fromProfile.id,
            rowData.toProfile.id
          )
        }
      >
        Annuler
      </Button>
    );
  };

  const gain = (rowData) => {
    if (rowData?.paymentMethod === "ONLINE") {
      return rowData.rate.price - frais(rowData);
    } else if (rowData?.paymentMethod === "CASH") {
      return rowData.rate.price;
    } else {
      return rowData.rate.price - (rowData.rate.price * 15) / 100;
    }
  };

  useEffect(() => {
    if (isLoaded === false) {
      initContent();
      setIsLoaded(true);
    }
    return () => {
      setReservation(null);
    };
  }, []);

  const handleSelectionChange = (rowData, field) => {
    const userId =
      field === "toProfile.lastName" || field === "toProfile.firstName"
        ? rowData.toProfile.userId
        : field === "fromProfile.lastName" || field === "fromProfile.firstName"
        ? rowData.fromProfile.userId
        : false;

    if (userId) {
      setSelectedCell(userId);
      window.open(`/profils?detailedProfileId=${userId}`);
    } else {
      setSelectedCell(false);
    }
  };

  const renderHeader = () => {
    return (
      <div className="table-header">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success mr-2"
          data-pr-tooltip="XLS"
          style={{
            width: "40px",
            backgroundColor: "var(--main-yellow)",
            border: 0,
          }}
        />
      </div>
    );
  };
  const header = renderHeader();

  return (
    <>
      <div
        className="RESERVATION"
        style={{
          alignItems: "center",
          width: "95%",
          height: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {!isEmpty(reservation) && (
          <DataTable
            value={reservation}
            paginator
            className="profilTable"
            stripedRows
            scrollable
            scrollDirection="both"
            scrollHeight="flex"
            sortField="id"
            sortOrder={-1}
            paginatorPosition="bottom"
            rows={10}
            dataKey="id"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" /*{this.paginatorTemplate}*/
            selectionMode="single"
            cellSelection
            header={header}
            selection={selectedCell}
            onSelectionChange={(e) => {
              handleSelectionChange(e.value.rowData, e.value.field);
            }}
          >
            <Column
              style={{
                minWidth: "250px",
                backgroundColor: "#333",
                color: "white",
                whiteSpace: "nowrap",
                maxWidth: "250px",
                overflow: "hidden",
              }}
              // body={mailBodyTemplate}
              field="date"
              sortable
              filter
              frozen
              header=" *                                                 *     Date de Prestation"
              body={(rowData) => {
                const dateParts = rowData.date[0]?.split(".");
                if (dateParts && dateParts.length > 0) {
                  const date = dateParts[0];
                  return <span>{date}</span>;
                }
                return null; // Handle cases where rowData.user?.creationDate is undefined or invalid
              }}
            ></Column>
            <Column
              style={{ minWidth: "201px" }}
              sortable
              filter
              header="Resérvé par "
              field="fromProfile.firstName"
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              sortable
              filter
              header=""
              field="fromProfile.lastName"
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              sortable
              filter
              header="Avec "
              field="toProfile.firstName"
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              sortable
              filter
              header=""
              field="toProfile.lastName"
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              sortable
              filter
              header="Rôle"
              field="toProfile.role"
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              sortable
              filter
              header="Activité"
              field="activity"
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              sortable
              filter
              header="Tarif"
              field="rate.price"
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              header="Frais"
              body={frais}
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              header="Prix payé"
              body={(rawData) => {
                return <div>{rawData.rate.price + frais(rawData)}</div>;
              }}
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              header="Commission"
              body={commission}
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              header="Rémunération"
              body={gain}
            ></Column>
            <Column
              style={{ minWidth: "251px" }}
              sortable
              filter
              header="Durée (minutes)"
              field="rate.duration"
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              sortable
              filter
              header="Etat"
              field="status"
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              sortable
              filter
              header="Moyent de paiement"
              field="paymentMethod"
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              sortable
              filter
              header="Date de Création"
              field="createdAt"
              body={(rowData) => {
                const dateParts = rowData.createdAt?.split(".");
                if (dateParts && dateParts.length > 0) {
                  const date = dateParts[0];
                  return <span>{date}</span>;
                }
                return null; // Handle cases where rowData.user?.creationDate is undefined or invalid
              }}
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              sortable
              filter
              header="Date de Modification"
              field="updatedAt"
              body={(rowData) => {
                const dateParts = rowData.updatedAt?.split(".");
                if (dateParts && dateParts.length > 0) {
                  const date = dateParts[0];
                  return <span>{date}</span>;
                }
                return null; // Handle cases where rowData.user?.creationDate is undefined or invalid
              }}
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              sortable
              filter
              header="Date Fin"
              field="finishedAt"
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              sortable
              filter
              header="Compte rendu"
              field="cr"
            ></Column>
            <Column
              style={{ minWidth: "151px" }}
              sortable
              filter
              header="Annuler"
              body={cancelTemplate}
            ></Column>
          </DataTable>
        )}
      </div>
    </>
  );
};

Reservation.propTypes = {};
Reservation.defaultProps = {};
export default Reservation;
