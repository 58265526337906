import { useEffect, useRef, useState } from "react";

import "chart.js/auto";

import axios from "../../services/axios/Axios";
import { DataTable, SortOrder } from "primereact/datatable";
import { Column } from "primereact/column";
import DetailedProfil from "./DetailedProfil";
import { addLocale, locale } from "primereact/api";
import { Button } from "primereact/button";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primereact/api";

import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { PremiumEnum, ProfileStatusEnum } from "../../services/enums";
import { useLocation } from "react-router";
import { Company } from "../../types/company.type";
import { ProfileData } from "../../types/profile.type";
import { ProfileUpdate } from "../../types/update.type";
import { InitialState } from "../../types/filters.type";

const Profils = (props: any) => {
  const location = useLocation();
  addLocale("fr", {
    clear: "Effacer",
    matchAll: "Tous les filtres",
    matchAny: "Un des filtres",
    addRule: "Ajouter un filtre",
    apply: "Appliquer",
    startsWith: "Commence par",
    contains: "Contient",
    notContains: "Ne contient pas",
    endsWith: "Se termine par",
  });
  locale("fr");

  const roles = ["HELPEE", "HELPER", "HELPRO"];
  const status = [
    "INCOMPLET",
    "BLOQUÉ",
    "LIVE",
    "DÉSINSCRIT",
    "SUPPRIMÉ",
    "INACTIF_1",
    "INACTIF_6",
    "INACTIF_12",
  ];
  const identity = ["TODO", "OK", "OK_AA", "NOK_AA", "NOK"];
  const quality = ["TODO", "OK", "OK_AA", "TOP", "NOK_AA", "NOK"];
  const work = ["TODO", "OK", "OK_AA", "NOK_AA", "NOK", "ALERTE"];
  const welcome = ["TODO", "OK", "MESS"];
  const updates = ["NEW", "OK"];
  const canLike = ["Oui", "Non"];
  const completionStep = ["1", "2", "3", "4", "5", "6", "7", "8"];
  const [companyNames, setCompanyNames] = useState([]);
  const urlOriginRef: any = useRef(null);
  const [profilesData, setProfilesData] = useState<ProfileData[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [xlsxData, setXlsxData] = useState<any>([]);

  console.log("profilesData", totalRecords, profilesData);

  const initialState: InitialState = {
    first: 0,
    rows: 10,
    page: 0,
    sortField: "user.creationDate",
    sortOrder: -1,
    filters: {
      global: { value: "", matchMode: FilterMatchMode.CONTAINS },
      "user.email": {
        operator: FilterOperator.AND,
        constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "profile.role": {
        value: ["HELPEE", "HELPER", "HELPRO"],
        matchMode: FilterMatchMode.IN,
      },
      "profile.firstName": {
        operator: FilterOperator.AND,
        constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "profile.tutorFirstName": {
        operator: FilterOperator.AND,
        constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "profile.lastName": {
        operator: FilterOperator.AND,
        constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "profile.tutorLastName": {
        operator: FilterOperator.AND,
        constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "profile.zipCode": {
        operator: FilterOperator.AND,
        constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
      },
      // Rajout feature filtre par numéro de téléphone
      "profile.phoneNumber": {
        operator: FilterOperator.AND,
        constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "profile.tutorPhoneNumber": {
        operator: FilterOperator.AND,
        constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
      },
      // Rajout feature filtre par id
      "profile.id": {
        operator: FilterOperator.AND,
        constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "user.creationDate": {
        operator: FilterOperator.AND,
        constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "profile.profileStatus": {
        value: [
          "INCOMPLET",
          "BLOQUÉ",
          "LIVE",
          "INACTIF_1",
          "INACTIF_6",
          "INACTIF_12",
        ],
        matchMode: FilterMatchMode.IN,
      },
      profileCompany: {
        value: [],
        matchMode: FilterMatchMode.IN,
      },
      "profile.updates": {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: "",
            flatten: true,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      "profile.completionStep": {
        value: ["1", "2", "3", "4", "5", "6", "7", "8"],
        operator: FilterOperator.AND,
        constraints: [
          {
            value: "",
            flatten: true,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      "profile.locked": {
        operator: FilterOperator.AND,
        constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "profile.completed": {
        operator: FilterOperator.AND,
        constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "profile.identityDocumentDateIsValid": {
        operator: FilterOperator.AND,
        constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "profile.identityAppreciation": {
        value: ["TODO", "OK", "OK_AA", "NOK_AA", "NOK"],
        operator: FilterOperator.AND,
        constraints: [
          { value: "", flatten: true, matchMode: FilterMatchMode.CONTAINS },
        ],
      },
      "profile.workAppreciation": {
        value: ["TODO", "OK", "OK_AA", "NOK_AA", "NOK", "ALERTE"],
        operator: FilterOperator.AND,
        constraints: [
          { value: "", flatten: true, matchMode: FilterMatchMode.CONTAINS },
        ],
      },
      "profile.qualityAppreciation": {
        value: ["TODO", "OK", "OK_AA", "TOP", "NOK_AA", "NOK"],
        operator: FilterOperator.AND,
        constraints: [
          { value: "", flatten: true, matchMode: FilterMatchMode.CONTAINS },
        ],
      },
      "profile.welcomeMessage": {
        value: ["TODO", "OK", "MESS"],
        operator: FilterOperator.AND,
        constraints: [
          { value: "", flatten: true, matchMode: FilterMatchMode.CONTAINS },
        ],
      },
      "profile.createdMissions": {
        operator: FilterOperator.AND,
        constraints: [
          { value: "", flatten: true, matchMode: FilterMatchMode.CONTAINS },
        ],
      },
      "profile.completedAt": {
        operator: FilterOperator.AND,
        constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "user.currentPlan": {
        operator: FilterOperator.AND,
        constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
      },
      filterByDate: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: false,
            flatten: true,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      origin: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: "",
            flatten: true,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      "profile.automationIncentiveLike": {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: "",
            flatten: true,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
    },
  };
  const [lazyState, setlazyState] = useState(initialState);
  // globalFilter for search in all columns
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onSort = (event: any) => {
    event["first"] = 0;
    event["page"] = 0;
    setlazyState(event);
    getDataPage(event);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    event["page"] = 0;
    setlazyState(event);
    getDataPage(event);
  };

  const onPage = (event: any) => {
    setlazyState(event);
    getDataPage(event);
  };

  const sortOrderToString = (order: any) => {
    const stringOrder = order === 1 ? "ASC" : "DESC";
    return stringOrder;
  };

  const getDataPage = async (pageOptions: InitialState, takeOption?: any) => {
    const take = takeOption ? takeOption : 10;
    const filterBody: any = {};
    const filtersKeys = Object.keys(pageOptions.filters);
    for (let i = 0; i < filtersKeys.length; i++) {
      // exclude role from global filters and check if value exists for each column
      if (
        filtersKeys[i] !== "profile.role" &&
        filtersKeys[i] !== "global" &&
        pageOptions.filters?.[filtersKeys[i]]?.constraints?.[0].value
      ) {
        filterBody[filtersKeys[i]] = pageOptions.filters?.[
          filtersKeys[i]
        ]?.constraints.map((filter: any) => filter.value);
        if (pageOptions.filters?.[filtersKeys[i]]?.constraints?.[0].flatten) {
          filterBody[filtersKeys[i]] = filterBody[filtersKeys[i]].flat();
        }
      }
    }

    const globalSearchQuery = pageOptions.filters?.global?.value
      ? "&search=" + pageOptions.filters?.global?.value
      : "";
    const orderQuery = pageOptions.sortOrder
      ? "&order=" + sortOrderToString(pageOptions.sortOrder)
      : "";
    const sortQuery = pageOptions.sortField
      ? "&sort=" + pageOptions.sortField
      : "";
    const roleFilterQuery = pageOptions.filters?.["profile.role"]?.value
      .map((role: any) => `&profile.role=${role}`)
      .join("");
    const profileStatusFilterQuery = pageOptions.filters?.[
      "profile.profileStatus"
    ]?.value
      .map((profileStatus: any) => `&profileStatus=${profileStatus}`)
      .join("");
    const companiesFilterQuery = pageOptions.filters?.["profileCompany"]?.value
      .map((profileCompany: any) => `&profileCompany=${profileCompany}`)
      .join("");
    const resData = await axios.post(
      `/profiles/complexUsersPaginate?page=${
        takeOption ? 1 : pageOptions.page + 1
      }&take=${take}` +
        orderQuery +
        sortQuery +
        roleFilterQuery +
        profileStatusFilterQuery +
        companiesFilterQuery +
        globalSearchQuery,
      { filterBody }
    );

    setTotalRecords(resData?.data?.complexUsers?.meta?.itemCount);
    if (take !== 10) {
      setXlsxData(
        resData?.data?.complexUsers.data.map((e: any) => {
          const profileFormat: any = {};

          for (const key in e.profile) {
            if (typeof key === "object" && key !== null) {
              profileFormat[key] = JSON.stringify(e.profile[key]);
            } else if (Array.isArray(e.profile[key])) {
              profileFormat[key] = e.profile[key]
                .map((e: any) => {
                  if (typeof e === "object" && e !== null) {
                    return JSON.stringify(e);
                  } else {
                    return e;
                  }
                })
                .join(", ");
            } else {
              profileFormat[key] = e.profile[key];
            }
          }

          return {
            ...e,
            profile: {
              ...profileFormat,
              /* We create a new key identityDocumentDateIsValid (0: not valid, 1: valid, "": no date) */
              identityDocumentDateIsValid:
                profileFormat.identityDocumentValidityDate
                  ? Number(
                      isIdentityDocumentValid(
                        e.profile.identityDocumentValidityDate
                      )
                    )
                  : "",
            },
          };
        })
      );
    } else {
      setProfilesData(
        resData?.data?.complexUsers.data.map((e: any) => ({
          ...e,
          profile: {
            ...e.profile,
            /* We create a new key identityDocumentDateIsValid (0: not valid, 1: valid, "": no date) */
            identityDocumentDateIsValid: e.profile.identityDocumentValidityDate
              ? Number(
                  isIdentityDocumentValid(
                    e.profile.identityDocumentValidityDate
                  )
                )
              : "",
          },
        }))
      );
    }
  };

  const getCompanyNames = () => {
    axios.get("/companies").then((response) => {
      const companyNames = response.data.map(
        (company: Company) => company.name
      );
      setCompanyNames(companyNames);
    });
  };

  useEffect(() => {
    const getQueryParams = () => {
      return new URLSearchParams(location.search);
    };
    const initialFilterProp = getQueryParams().get("initialFilter");
    const queryOrigin = getQueryParams().get("origin");
    urlOriginRef.current = queryOrigin;
    if (initialFilterProp) {
      switch (initialFilterProp) {
        case "workPermitValidity":
          initialState.filters[
            "profile.workAppreciation"
          ].constraints[0].value = ["ALERTE"];
          initialState.filters["origin"].constraints[0].value = "dashboard";
          break;

        case "newUpdates":
          initialState.filters["profile.updates"].constraints[0].value = [
            "NEW",
          ];
          initialState.filters["origin"].constraints[0].value = "dashboard";
          break;

        case "newBlocked":
          initialState.filters["profile.profileStatus"].value = ["BLOQUÉ"];
          initialState.filters[
            "profile.qualityAppreciation"
          ].constraints[0].value = ["TODO"];
          initialState.filters[
            "profile.workAppreciation"
          ].constraints[0].value = ["TODO"];
          initialState.filters[
            "profile.identityAppreciation"
          ].constraints[0].value = ["TODO"];
          initialState.filters["origin"].constraints[0].value = "dashboard";
          break;

        case "welcomeProfiles":
          initialState.filters["profile.profileStatus"].value = ["LIVE"];
          initialState.filters["profile.welcomeMessage"].constraints[0].value =
            ["TODO"];
          initialState.filters["origin"].constraints[0].value = "dashboard";
          break;

        case "incompleteProfiles":
          initialState.filters["profile.profileStatus"].value = ["INCOMPLET"];
          initialState.filters["profile.completionStep"].constraints[0].value =
            ["1", "2", "3", "4", "5", "6", "7", "8"];
          initialState.filters["filterByDate"].constraints[0].value = true;
          initialState.filters["origin"].constraints[0].value = "dashboard";
          break;

        case "blockedQualityOK":
          initialState.filters["profile.profileStatus"].value = ["BLOQUÉ"];
          initialState.filters[
            "profile.qualityAppreciation"
          ].constraints[0].value = ["OK"];
          initialState.filters["filterByDate"].constraints[0].value = true;
          initialState.filters["origin"].constraints[0].value = "dashboard";
          break;

        case "canLikeAgain":
          initialState.filters["profile.role"].value = ["HELPEE"];
          initialState.filters["profile.profileStatus"].value = ["LIVE"];
          initialState.filters[
            "profile.automationIncentiveLike"
          ].constraints[0].value = ["true"];
          initialState.filters["origin"].constraints[0].value = "dashboard";
          break;

        case "cannotLike":
          initialState.filters["profile.role"].value = ["HELPEE"];
          initialState.filters["profile.profileStatus"].value = ["LIVE"];
          initialState.filters[
            "profile.automationIncentiveLike"
          ].constraints[0].value = ["false"];
          initialState.filters["origin"].constraints[0].value = "dashboard";
          break;

        case "noAnswer":
          initialState.filters["profile.role"].value = ["HELPEE"];
          initialState.filters["profile.createdMissions"].constraints[0].value =
            ["OK"];
          initialState.filters["origin"].constraints[0].value = "dashboard";
          break;

        case "didnotReplyToHelper":
          initialState.filters["profile.role"].value = ["HELPEE"];
          initialState.filters["profile.createdMissions"].constraints[0].value =
            ["NO_REPLY"];
          initialState.filters["origin"].constraints[0].value = "dashboard";
          break;

        default:
          break;
      }
      getDataPage(lazyState, null);
    } else {
      getDataPage(lazyState);
    }
    getCompanyNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("detailedProfileId")) {
      const detailedProfileId = urlParams.get("detailedProfileId");

      props.actions.setAddSubform({
        component: DetailedProfil,
        data: {
          id: detailedProfileId,
          updateParent: () => getDataPage(lazyState),
        },
        actions: props.actions,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Check if the ID document date is valid */
  const isIdentityDocumentValid = (date: string) => {
    return (
      moment(date).diff(
        moment(), // Check if the validity date is GT 0
        "months",
        true
      ) > 0
    );
  };

  var parse = require("html-react-parser");

  const saveAsExcelFile = (buffer: any, fileName: any) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(data, fileName + EXCEL_EXTENSION);
      }
    });
  };

  useEffect(() => {
    const exportExcel = () => {
      let arrangedTab: any[] = [];
      arrangedTab.push(
        Object.keys(xlsxData[0])
          .slice(3, 6)
          .concat(
            Object.keys(xlsxData[0].user).concat(
              Object.keys(xlsxData[0].profile)
            )
          )
      );
      for (let i = 0; i < xlsxData.length; i++) {
        arrangedTab.push(
          Object.values(xlsxData[i])
            .slice(3, 6)
            .concat(
              Object.values(xlsxData[i].user).concat(
                Object.values(xlsxData[i].profile)
              )
            )
        );
      }
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(arrangedTab);
        const workbook = {
          Sheets: { data: worksheet },
          SheetNames: ["data"],
        };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        saveAsExcelFile(excelBuffer, moment(Date.now()).format("YY-MM-DD"));
      });
    };
    xlsxData.length > 0 && exportExcel();
  }, [xlsxData]);

  const lastComm = (rowData: any) => {
    return rowData.user.comment?.split("<br/>")[0] !== undefined ? (
      <div className="tableComment">
        {parse(rowData.user.comment?.split("<br/>")[0])}
      </div>
    ) : (
      ""
    );
  };

  // SET FILTERS
  const representativeRowFilterTemplate = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={options.field === "profile.role" ? roles : status}
        itemTemplate={(option) => <span>{option}</span>}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder={options.field === "profile.role" ? "Role" : "Statut"}
        className="p-column-filter"
        maxSelectedLabels={0}
        style={{ minWidth: "10rem" }}
        display="chip"
      />
    );
  };
  const profileStatusRowFilterTemplate = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={status}
        itemTemplate={(option) => <span>{option}</span>}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Status"
        className="p-column-filter"
        maxSelectedLabels={0}
        style={{ minWidth: "10rem" }}
        display="chip"
      />
    );
  };
  const companiesFilterTemplate = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={companyNames}
        itemTemplate={(option) => <span>{option}</span>}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Entreprises"
        className="p-column-filter"
        maxSelectedLabels={0}
        style={{ minWidth: "10rem" }}
        display="chip"
      />
    );
  };

  const qualityRowFilterTemplate = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={quality}
        itemTemplate={(option) => <span>{option}</span>}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Qualité"
        className="p-column-filter"
        maxSelectedLabels={0}
        style={{ minWidth: "10rem" }}
        display="chip"
      />
    );
  };
  const identityRowFilterTemplate = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={identity}
        itemTemplate={(option) => <span>{option}</span>}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Identité"
        className="p-column-filter"
        maxSelectedLabels={0}
        style={{ minWidth: "10rem" }}
        display="chip"
      />
    );
  };
  const workRowFilterTemplate = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={work}
        itemTemplate={(option) => <span>{option}</span>}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Travail"
        className="p-column-filter"
        maxSelectedLabels={0}
        style={{ minWidth: "10rem" }}
        display="chip"
      />
    );
  };
  const welcomeRowFilterTemplate = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={welcome}
        itemTemplate={(option) => <span>{option}</span>}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Bienvenue"
        className="p-column-filter"
        maxSelectedLabels={0}
        style={{ minWidth: "10rem" }}
        display="chip"
      />
    );
  };
  const profileUpdatesRowFilterTemplate = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={updates}
        itemTemplate={(option) => <span>{option}</span>}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Updates"
        className="p-column-filter"
        maxSelectedLabels={0}
        style={{ minWidth: "10rem" }}
        display="chip"
      />
    );
  };
  const profileCanLikeRowFilterTemplate = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={canLike}
        itemTemplate={(option) => <span>{option}</span>}
        onChange={(e) => options.filterApplyCallback(e.value)}
        className="p-column-filter"
        maxSelectedLabels={0}
        style={{ minWidth: "10rem" }}
        display="chip"
      />
    );
  };
  const profileCompletionStepFilterTemplate = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={completionStep}
        itemTemplate={(option) => <span>{option}</span>}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Étape"
        className="p-column-filter"
        maxSelectedLabels={0}
        style={{ minWidth: "10rem" }}
        display="chip"
      />
    );
  };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...lazyState.filters };

    _filters["global"].value = value;
    const stateWithSearch = { ...lazyState, filters: _filters };
    setlazyState(stateWithSearch);
    setGlobalFilterValue(value);
    getDataPage(stateWithSearch);
  };

  const initFilters = () => {
    setlazyState(initialState);
    setGlobalFilterValue("");
    getDataPage(initialState);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div>
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Clear"
            onClick={clearFilter}
          />
          <Button
            type="button"
            icon="pi pi-file-excel"
            onClick={() => {
              getDataPage(lazyState, totalRecords);
            }}
            className="p-button-success mr-2"
            label="Exporter"
            data-pr-tooltip="XLS"
            style={{
              marginLeft: "20px",
              backgroundColor: "var(--main-yellow)",
              borderColor: "var(--main-yellow)",
            }}
          />
        </div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" style={{ paddingLeft: 10 }} />
          <InputText
            style={{ paddingLeft: 30 }}
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Recherche"
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();

  const formatPremiumStatusField = (premiumField: string) => {
    try {
      const status = PremiumEnum.find((status) => status.code === premiumField);
      return status?.name || premiumField; // Fallback to original value if not found
    } catch (error) {
      console.log("error", error);
    }
  };

  const formatProfileStatusField = (rowData: any) => {
    try {
      const correctStatus = ProfileStatusEnum.find(
        (status) => status.name === rowData
      );
      const status = {
        color: correctStatus?.color ?? "black",
        name: correctStatus?.name ?? rowData,
      };
      return <span style={{ color: status.color }}>{status.name}</span>;
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div
        className="DATATABLE-CONTAINER"
        style={{
          height: "100%",
          width: "95%",
          margin: "auto",
          overflow: "hidden",
          borderRadius: "8px",
        }}
      >
        <DataTable
          value={profilesData}
          className="profilTable"
          stripedRows
          scrollable
          scrollHeight="flex"
          lazy
          paginator
          paginatorPosition="bottom"
          onPage={onPage}
          first={lazyState.first}
          rows={10}
          totalRecords={totalRecords}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder as SortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          filterDisplay="menu"
          globalFilterFields={[
            "profile.email",
            "profile.role",
            "profile.lastName",
            "profile.firstName",
          ]}
          header={header}
          onSelectionChange={(e: any) => {
            props.actions.setAddSubform({
              component: DetailedProfil,
              data: {
                id: e.value.id,
                reviewId: e.value.profile.updates?.[0]?.id,
                originParam: urlOriginRef.current,
                updateParent: () => getDataPage(lazyState),
              },
              actions: props.actions,
            });
          }}
          dataKey="id"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          selectionMode="single"
        >
          <Column
            style={{ minWidth: "205.79px" }}
            sortable
            filter
            field="user.creationDate"
            header="Date de création"
          ></Column>
          <Column header="ID" field="profile.id" filter></Column>
          <Column
            style={{ minWidth: "125px" }}
            field="profile.role"
            bodyClassName={(rowdata) => rowdata.role}
            sortable
            filter
            filterElement={representativeRowFilterTemplate}
            header="Rôle"
          ></Column>
          <Column
            style={{ minWidth: "145.95px" }}
            field="profile.firstName"
            body={(rowdata) =>
              rowdata.profile?.role !== "HELPEE"
                ? rowdata.profile?.firstName
                : rowdata.profile?.tutorFirstName
            }
            header="Prénom"
            filter
          ></Column>
          <Column
            style={{ minWidth: "145px" }}
            field="profile.lastName"
            body={(rowdata) =>
              rowdata.profile?.role !== "HELPEE"
                ? rowdata.profile?.lastName
                : rowdata.profile?.tutorLastName
            }
            header="Nom"
            sortable
            filter
          ></Column>
          <Column
            style={{ minWidth: "145px" }}
            field="profile.firstName"
            body={(rowdata) =>
              rowdata.profile?.role === "HELPEE"
                ? rowdata.profile?.firstName
                : ""
            }
            header="Aidé"
            sortable
            filter
          ></Column>
          <Column
            style={{ minWidth: "175px" }}
            header="Code Postal"
            field="profile.zipCode"
            sortable
            filter
          ></Column>
          <Column
            style={{
              minWidth: "250px",
              maxWidth: "250px",
              overflow: "hidden",
            }}
            header="Mail"
            field="user.email"
            sortable
            filter
          ></Column>
          <Column
            header="Téléphone"
            field={"profile.phoneNumber"}
            filter
          ></Column>
          <Column
            header="Statut"
            field={"profile.profileStatus"}
            body={(rowData) => {
              return formatProfileStatusField(rowData.profile.profileStatus);
            }}
            sortable
            filter
            filterElement={profileStatusRowFilterTemplate}
          ></Column>
          <Column
            header="Étape"
            field={"profile.completionStep"}
            sortable
            filter
            filterElement={profileCompletionStepFilterTemplate}
          ></Column>
          <Column
            header="Statut Premium"
            field={"user.currentPlan"}
            body={(rowData) =>
              rowData.user.currentPlan === "PREMIUM_MONTHLY" ? (
                <span style={{ color: "green" }}>
                  {formatPremiumStatusField(rowData.user.currentPlan)}
                </span>
              ) : null
            }
            filter
            sortable
          ></Column>
          <Column
            header="Identité"
            field={"profile.identityAppreciation"}
            body={(rowData) => {
              if (
                rowData.profile?.role === "HELPEE" &&
                rowData.profile?.identityAppreciation === "TODO"
              ) {
                return <span>N/A</span>;
              } else {
                return <span>{rowData.profile.identityAppreciation}</span>;
              }
            }}
            sortable
            filter
            filterElement={identityRowFilterTemplate}
          ></Column>
          <Column
            header="Travail"
            field={"profile.workAppreciation"}
            body={(rowData) => {
              if (
                rowData.profile?.role === "HELPEE" &&
                rowData.profile?.workAppreciation === "TODO"
              ) {
                return <span>N/A</span>;
              } else {
                return <span>{rowData.profile.workAppreciation}</span>;
              }
            }}
            sortable
            filter
            filterElement={workRowFilterTemplate}
          ></Column>
          <Column
            header="Qualité"
            field={"profile.qualityAppreciation"}
            sortable
            filter
            filterElement={qualityRowFilterTemplate}
          ></Column>
          <Column
            header="Bienvenue"
            field={"profile.welcomeMessage"}
            sortable
            filter
            filterElement={welcomeRowFilterTemplate}
          ></Column>
          <Column
            header="Modifications"
            field={"profile.updates"}
            body={(rowData) =>
              rowData.profile.updates.some(
                (update: ProfileUpdate) => update.updateReviewStatus === "NEW"
              ) ? (
                <span style={{ color: "red" }}>NEW</span>
              ) : null
            }
            filter
            filterElement={profileUpdatesRowFilterTemplate}
            sortable
          ></Column>
          <Column
            header="A des résultats"
            field={"profile.automationIncentiveLike"}
            body={(rowData) =>
              rowData?.profile?.automationIncentiveLike?.canLike === true ? (
                <span style={{ color: "green" }}>Oui</span>
              ) : (
                <span style={{ color: "red" }}>Non</span>
              )
            }
            filter
            filterElement={profileCanLikeRowFilterTemplate}
            sortable
          ></Column>
          <Column
            header="A posté une mission"
            field={"profile.createdMissions"}
            sortable
            body={(rowData) =>
              rowData?.profile?.role !== "HELPEE" ? (
                <span style={{ color: "grey" }}>N/A</span>
              ) : rowData?.profile?.createdMissions?.length > 0 ? (
                <span style={{ color: "green" }}>Oui</span>
              ) : (
                <span style={{ color: "red" }}>Non</span>
              )
            }
          />
          <Column
            header="Entreprise"
            field={"profileCompany"}
            sortable
            filter
            filterElement={companiesFilterTemplate}
          ></Column>
          <Column header="Dernier commentaire" body={lastComm}></Column>
        </DataTable>
      </div>
    </>
  );
};
export default Profils;
