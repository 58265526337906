import axios from "../../../services/axios/Axios";
import { setBackOfficeBearer } from "../../reducers/general";

const LOGIN = "LOGIN";
export const Login = (params) => ({
  type: LOGIN,
  params,
});
export default (store) => (next) => (action) => {
  switch (action.type) {
    case LOGIN:
      {
        const formatedData = action.params;
        axios.post(`/auth/login`, formatedData).then((resp) => {
          store.dispatch(setBackOfficeBearer(resp.data.token));
        });
      }
      break;

    default:
  }
  next(action);
};
